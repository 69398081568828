export const STANDARD_REPLACER_CHANGE_ACTIVE =
	'STANDARD_REPLACER_CHANGE_ACTIVE';

export const CUSTOM_REPLACER_CREATE = 'CUSTOM_REPLACER_CREATE';
export const CUSTOM_REPLACER_REMOVE = 'CUSTOM_REPLACER_REMOVE';
export const CUSTOM_REPLACER_CHANGE_DATA = 'CUSTOM_REPLACER_CHANGE_DATA';
export const CUSTOM_REPLACER_CHANGE_ACTIVE = 'CUSTOM_REPLACER_CHANGE_ACTIVE';

export const AUTO_PROCESS_TEXT_CHANGE_ACTIVE =
	'AUTO_PROCESS_TEXT_CHANGE_ACTIVE';

export const AUTO_COPY_TEXT_CHANGE_ACTIVE = 'AUTO_COPY_TEXT_CHANGE_ACTIVE';

export const USER_INPUT_TEXT_CHANGE = 'USER_INPUT_TEXT_CHANGE';

export const PROCESS_TEXT = 'PROCESS_TEXT';

export const SET_SHOW_COPY_TO_CLIPBOARD_ALERT =
	'SET_SHOW_COPY_TO_CLIPBOARD_ALERT';
